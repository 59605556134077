<template>
  <FormKit type="group" name="password">
    <Password
      id="password"
      name="password"
      :label="label"
      required-message="formFields.password.emptyError"
      :validation-visible="validationVisible"
      :autofocus="autofocus"
    />
    <Password
      id="passwordConfirm"
      name="passwordConfirm"
      :label="confirmLabel"
      required-message="formFields.password.confirmEmptyError"
      confirm-field="password"
      @change="() => (validationVisible = true)"
    />
  </FormKit>
</template>
<script setup lang="ts">
import Password from '~~/src/components/formFields/passwordValidation/minilu/password.vue';
import { FormKit } from '@formkit/vue';

defineProps({
  label: {
    type: String,
    required: false,
    default: 'formFields.password.label',
  },
  confirmLabel: {
    type: String,
    required: false,
    default: 'formFields.password.confirmLabel',
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const validationVisible = ref(false);
</script>
